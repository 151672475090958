import {inject, ViewSlot, Container, ViewCompiler, bindable, customElement} from 'aurelia-framework';
import * as _ from 'lodash';
import {UnsavedChangesChecker} from "../../form/unsaved-changes-checker";
import {TabProcessor} from "./tab-processor";
import {SelectedTabStorage} from "./selected-tab-storage.js";

import "./tab.less";
import {UnsavedChanges} from "../../form/unsaved-changes-prevent";

@inject(
    TabProcessor,
    ViewSlot,
    Container,
    ViewCompiler,
    UnsavedChangesChecker,
    SelectedTabStorage
)
@customElement('sio-new-tabs')
export class NewTabs {

    @bindable
    config;

    @bindable
    object;

    _presets = {
        contingentCalendar: [{
            heading: 'price.models.contingent-calendar.display.tabs.calendar.heading',
            content: '<sio-contingent-view></sio-contingent-view>'
        }, {
            heading: 'price.models.contingent-calendar.display.tabs.table.heading',
            content: '<sio-table config=\"price/contingent-calendar\"></sio-table>'
        }],
        tourGuideCalendar: [{
            heading: 'tour-guide-tourism.tabs.calendar.heading',
            content: '<sio-guide-calendar  post-url.bind="\'tour-guide-tourism/guide-calendar\'" form-id.bind="\'tour-guide-tourism/guide-calendar-request\'"></sio-guide-calendar>'
        }, {
            heading: 'tour-guide-tourism.tabs.table.heading',
            content: '<sio-table config.one-time="\'tour-guide-tourism/guide-availability\'"></sio-table>'
        }]
    };

    selectedTab;
    _tabs = [];

    constructor(
        tabProcessor,
        viewSlot,
        container,
        viewCompiler,
        unsavedChangesChecker,
        selectedTabStorage
    ) {
        this.tabProcessor = tabProcessor;
        this.viewSlot = viewSlot;
        this.container = container;
        this.viewCompiler = viewCompiler;
        this.unsavedChangesChecker = unsavedChangesChecker;
        this.selectedTabStorage = selectedTabStorage;
    }

    async bind(context, overrideContext) {
        this.context = context;
        this.context.object = this.object;
        this.overrideContext = overrideContext;

        await this._update();
    }

    async configChanged() {
        await this._update();
    }

    async objectChanged() {
        this.context.object = this.object;
        await this._update();
    }

    async _update() {
        this._tabs = [];

        this._tabs = await this.tabProcessor.processTabs(this.config && this._presets?.[this.config] ? this._presets[this.config] : null, this.object, this.context, this.overrideContext);

        const tab = this.selectedTabStorage.getSelectedTab(this._tabs);

        if (!tab) {
            let activeTab = null;

            for (let i = 0; i < this._tabs.length; i++) {
                if (this._tabs[i].active && activeTab == null) {
                    activeTab = i;
                    break;
                }
            }

            if (this._tabs.length > 0 && !this.selectedTab) {
                this.originalUnsavedChanges = _.clone(UnsavedChanges.changes);
                this.selectTab(activeTab ? activeTab : 0);
            }
        } else {
            let activeTab = null;

            for (let i = 0; i < this._tabs.length; i++) {
                if (this._tabs[i] === tab) {
                    activeTab = i;
                    break;
                }
            }

            if (this._tabs.length > 0 && !this.selectedTab) {
                this.originalUnsavedChanges = _.clone(UnsavedChanges.changes);
                this.selectTab(activeTab ? activeTab : 0);
            }
        }
    }

    selectTab(index) {

        if (!this.unsavedChangesChecker.canDeactivate(this.originalUnsavedChanges)) {
            return;
        }

        if (index === this.selectedTab) {
            return;
        }

        //Remove old view
        if (this.view) {

            this.view.unbind();
            this.viewSlot.remove(this.view, true, true);
        }

        if (this.selectedTab != null) {
            this.selectedTabStorage.selectTab(this._tabs[index]);
        }

        //Add new view
        const tab = this._tabs[index];
        this.context.tab = tab;
        this.selectedTab = index;

        if (!tab.viewFactory) {
            tab.viewFactory = this.viewCompiler.compile('<template>' + tab.content + '</template>');
        }

        this.view = tab.viewFactory.create(this.container);
        this.view.bind(this.context, this.overrideContext);

        this.viewSlot.add(this.view);
    }
}
